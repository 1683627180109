import React from "react";
import { graphql } from "gatsby";
import { assetArrayToObject } from "../lib/graphql-helpers";
import { Hero } from "../components/hero";
import { orgs } from "../data/pages/philanthropy";
import { Org } from "../components/philanthropy";
import "../styles/pages/philanthropy.scss";

const Philanthropy = ({ data }) => {
  const {
    allFile: { edges: allImages },
  } = data;

  const images = assetArrayToObject({ images: allImages, useGatsbyImage: true });

  const heroData = {
    title: "Giving Back",
    image: `${images["philanthropyHero"].images.fallback.src}`,
  };

  return (
    <div className="philanthropy">
      <Hero data={heroData}/>
      <section className="philanthropy__text">
        <div className="philanthropy__text__inner">
          <p>
            Claypoole is a search firm that prioritizes giving back. As part of
            our mission, we identify and partner with nonprofits who share our
            values and make a tangible difference in their communities.
          </p>
        </div>
      </section>
      <section className="philanthropy__orgs">
        <div className="philanthropy__orgs__inner">
          {orgs.map((item) => {
            return (
              <Org key={item} data={item} image={images[item.image]}/>
            )
          })}
        </div>
      </section>
    </div>
  );
};

export const query = graphql`
  query {
    allFile(
      filter: {
        extension: { regex: "/(jpg|png|svg)/" }
        relativeDirectory: { eq: "philanthropy" }
      }
    ) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;

export default Philanthropy;
