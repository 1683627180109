export const orgs = [
  {
    title: "Prep for Prep",
    text: "We donate 5% of annual net profits to organizations which champion diversity, equity and inclusion. This year, we have chosen Prep for Prep, which creates access for young people of color to first-rate educational, leadership development and professional advancement opportunities.",
    image: "prepForPrep",
    link: "https://www.prepforprep.org/about/about-prep",
    aspectRatio: 690 / 507,
  },
  {
    title: "LimPiaR",
    text: "David is the co-founder of LimPiaR, a 501c3 organization dedicated to cleaning the coastal communities of Puerto Rico, the island he calls home.",
    image: "limpiar",
    link: "https://limpiar.org/",
    aspectRatio: 690 / 503,
  },
];
