import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Link } from '../links';
import "./orgs.scss";

const Org = ({ data, image }) => {
  const { title, text, link, aspectRatio } = data;
  return (
    <div className="org">
      <div className="org__image-wrapper">
        <GatsbyImage image={image} />
      </div>
      <div className="org__content">
        <h3>{title}</h3>
        <p>{text}</p>
        <Link className='cursor-pointer' isOutbound to={link}>Learn more</Link>
      </div>
    </div>
  );
}

export default Org
